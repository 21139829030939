import React from 'react';

import * as styles from './styles.module.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
interface FeatureCardProps {
  image: IGatsbyImageData | undefined;
  content: string;
  title: string;
}

const FeatureCard = (props: FeatureCardProps) => {
  const { image, title, content } = props;

  return (
    <div>
      <div>
        <GatsbyImage
          image={image as IGatsbyImageData}
          alt={`${title} icon`}
          className={styles.icon}
          imgStyle={{ height: '2.4rem', width: 'auto' }}
        />
      </div>
      <div>
        <div className={styles.content}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;

import React from 'react';

import * as styles from './styles.module.scss';
import Container from '../Container';

interface TextAreaProps {
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = (props: TextAreaProps) => {
  return (
    <Container className={`${styles.container} ${styles.theme}`}>
      <label htmlFor={props.id}>{props.label}</label>
      <textarea
        id={props.id}
        name={props.id}
        value={props.value}
        rows={4}
        className={styles.textarea}
        onChange={props.onChange}
      />
    </Container>
  );
};

export default TextArea;

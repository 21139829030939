// extracted by mini-css-extract-plugin
export var container = "styles-module--container--f335c";
export var content = "styles-module--content--6db5c";
export var description = "styles-module--description--bffd7";
export var linkContainer = "styles-module--link-container--65c92";
export var list = "styles-module--list--3f660";
export var listContainer = "styles-module--list-container--0f7b5";
export var listItem = "styles-module--list-item--d82ac";
export var productImage = "styles-module--product-image--218a9";
export var root = "styles-module--root--1e0aa";
export var subtitle = "styles-module--subtitle--0e830";
export var title = "styles-module--title--f05c0";
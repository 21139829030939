import React, { useRef } from 'react';

import FeatureCard from '../FeatureCard';
import * as styles from './styles.module.scss';
import { useActivePath } from '../../hooks';
import Container from '../Container';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { FileSystemNode } from 'gatsby-source-filesystem';

const Feature = () => {
  const imagesData = useStaticQuery(graphql`
    query imagesQuery {
      allFile(
        filter: {
          relativePath: {
            in: ["secure.png", "affordable.png", "resilient.png", "simple.png"]
          }
        }
      ) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const imageMap: Record<string, IGatsbyImageData | undefined> = {};
  imagesData.allFile.nodes.forEach((f: FileSystemNode) => {
    imageMap[f.name] = getImage(f);
  });
  const ref = useRef(null);
  useActivePath('/#features', ref);
  const featuresMap = [
    {
      id: 'simple',
      image: imageMap.simple,
      title: 'Simple',
      content:
        'Discover simplicity. Create production-grade Kafka clusters within minutes.',
    },
    {
      id: 'affordable',
      image: imageMap.affordable,
      title: 'Affordable',
      content:
        'Escape high cloud costs. Deploy your Kafka for as low as $14/month.',
    },
    {
      id: 'resilient',
      image: imageMap.resilient,
      title: 'Resilient',
      content:
        'Experience high resilence. Operate your Kafka instances with continuous monitoring, self-healing and elastic scalabilty.',
    },
    {
      id: 'secure',
      image: imageMap.secure,
      title: 'Secure',
      content:
        'Rest easy with enterprise-grade security protection. Experience continuous thread detection, runtime monitoring and alerting.',
    },
  ];

  return (
    <section id="features" ref={ref}>
      <Container className={`${styles.root} ${styles.theme}`}>
        <h2 className={styles.title}>The Bitnimbus.io Platform</h2>
        <p className={styles.caption}>
          Empowers Developers to Build World-class Software Products
        </p>
        <div className={styles.featuresContainer}>
          {featuresMap.map((feature, index) => (
            <div key={feature.id} className={styles.featureCard}>
              <FeatureCard {...feature} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Feature;

import React from 'react';

import * as styles from './styles.module.scss';
import Container from '../Container';

interface DropdownProps {
  options: string[];
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Dropdown = (props: DropdownProps) => {
  return (
    <Container className={`${styles.container} ${styles.theme}`}>
      <label htmlFor={props.id}>{props.label}</label>
      <div>
        <select
          value={props.value}
          id={props.id}
          name={props.id}
          className={styles.dropdown}
          onChange={props.onChange}
        >
          {props.options.map((option) => (
            <option value={option} key={option} className={styles.option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
};

export default Dropdown;

import React from 'react';
import cx from 'classnames';

import * as styles from '../CTA/styles.module.scss';
import Container from '../Container';

interface ButtonProps {
  type?: 'submit' | 'button' | 'reset';
  variant?: 'primary' | 'default' | 'outlined';
  disabled?: boolean;
  label: string;
}

const Button = (props: ButtonProps) => {
  return (
    <Container className={styles.theme}>
      <button
        type={props.type ? props.type : 'button'}
        disabled={props.disabled}
        className={cx({
          [styles.button]: true,
          [styles.primaryButton]: props.variant === 'primary',
          [styles.outlinedButton]: props.variant === 'outlined',
          [styles.defaultButton]: !props.variant || props.variant === 'default',
          [styles.disabledButton]: props.disabled,
        })}
      >
        {props.label}
      </button>
    </Container>
  );
};

export default Button;

import React from 'react';

import * as styles from './styles.module.scss';
import Container from '../Container';

interface TextField {
  label: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  onBlur?: () => void;
  helperText?: string;
  placeholder?: string;
}

const TextField = (props: TextField) => {
  const { label, onChange, id } = props;
  return (
    <Container className={`${styles.container} ${styles.theme}`}>
      <label htmlFor={id}>{label}</label>
      <div>
        <input
          name={id}
          value={props.value}
          type="text"
          className={styles.textField}
          onChange={onChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
        />
        {props.helperText && (
          <div className={styles.helperText}>{props.helperText}</div>
        )}
      </div>
    </Container>
  );
};

export default TextField;

import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';

import Home from '../components/Home';
import ContactUs from '../components/ContactUs';
import Feature from '../components/Features';
import ProductLanding from '../components/ProductLanding';
import { PageContext } from '../context';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const IndexPage: React.FC<PageProps> = () => {
  const [activePath, setActivePath] = React.useState('');

  const pageCtx = {
    activePath,
    setActivePath,
  };

  return (
    <PageContext.Provider value={pageCtx}>
      <Layout>
        <div>
          <Home />
          <ProductLanding />
          <Feature />
          <ContactUs />
        </div>
      </Layout>
    </PageContext.Provider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  return (
    <Seo
      title="Multicloud data platform - Bitnimbus.io"
      description="Experience seamless development with our multi-cloud data platform. Deploy your fully managed Kafka clusters in a few clicks on your favorite cloud providers and simplify your workflow."
      canonical="https://www.bitnmbus.io"
    />
  );
};
